<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <ddr-header></ddr-header>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ddr-content></ddr-content>
    </div>
  </div>


</div>